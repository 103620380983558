import { LanguageContext } from "@contexts";
import { cn } from "@lib";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { container } from "./LanguageSwitch.module.scss";

export const LanguageSwitch = ({ className }) => {
  const { selectedLanguage, changeLanguage } = useContext(LanguageContext);
  return (
    <div className={cn(container, className)}>
      <button
        style={selectedLanguage === "nb" ? { fontWeight: "bold" } : {}}
        onClick={() => changeLanguage("nb")}
      >
        Nor
      </button>
      /
      <button
        style={selectedLanguage === "en" ? { fontWeight: "bold" } : {}}
        onClick={() => changeLanguage("en")}
      >
        En
      </button>
    </div>
  );
};

LanguageSwitch.propTypes = {
  className: PropTypes.string,
};
