import PropTypes from "prop-types";
import React from "react";
import { Marker } from "react-map-gl";

const SIZE = 18;

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export const Pins = ({ data, onClick }) => {
  return data && data.location ? (
    <Marker longitude={data.location.lng} latitude={data.location.lat}>
      <svg
        height={SIZE}
        viewBox="0 0 50 50"
        style={{
          fill: data.pinColor ? data.pinColor : `var(--color-orange)`,
          stroke: "none",
          transform: `translate(${-SIZE / 2}px,${-SIZE}px)`,
        }}
        onClick={() => onClick && onClick(data)}
      >
        <circle cx="26" cy="26" r="24" fill="black" />
        <circle cx="24" cy="24" r="24" />
      </svg>
    </Marker>
  ) : (
    ""
  );
};

Pins.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
