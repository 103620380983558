import { LanguageContext } from "@contexts";
import { cn } from "@lib";
import { Heading1 } from "@ui/typography";
import React, { useContext, useEffect, useRef, useState } from "react";

import * as styles from "./HorizontalScrollingText.module.scss";

export const HorizontalScrollingText = ({
  texts,
  yOffset,
  speedOffset = 1,
  invert,
}) => {
  const [scrollPos, setScrollPos] = useState(0);
  const repeatCount = [0, 1, 0, 0, 0];
  const { localize } = useContext(LanguageContext);
  const wrapperRef = useRef(null);
  useEffect(() => {
    const calculatedYOffset = yOffset || wrapperRef.current.offsetTop - 1000;
    function onScroll() {
      setScrollPos(window.pageYOffset - calculatedYOffset);
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [yOffset]);
  return (
    <div
      ref={wrapperRef}
      className={cn(
        styles.horizontalTextContainer,
        invert ? styles.invert : "",
      )}
    >
      {texts.map((string, index) => (
        <div
          key={"header-" + string + index}
          className={styles.horizontalText}
          style={{
            transform: `translateX(${
              -800 + scrollPos * (index > 0 ? 0.6 : 0.4) * speedOffset
            }px)`,
          }}
        >
          {repeatCount.map((n, index) => (
            <Heading1
              uppercase
              className={n > 0 ? styles.filled : ""}
              key={string.nb + index}
            >
              {localize(string)}
            </Heading1>
          ))}
        </div>
      ))}
    </div>
  );
};
