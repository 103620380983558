import React, { useState } from "react";

const AllStuffMainContext = React.createContext();

const AllStuffMainProvider = ({ children }) => {
  const [data, setData] = useState({clickedTag: false});
  return (
    <AllStuffMainContext.Provider
      value={{ data, setData }}
    >
      {children}
    </AllStuffMainContext.Provider>
  );
};

export { AllStuffMainProvider, AllStuffMainContext };
