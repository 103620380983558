import { Image } from "@components";
import { cn } from "@lib";
import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import * as styles from "./ArticleImages.module.scss";

interface IProps {
  value: { images: { asset: any; alt: string; caption: string }[] };
}

export const ArticleImages: React.FC<IProps> = ({ value: { images } }) => {
  // console.log(props);

  const ImageCell = ({ image }) => (
    <div className={styles.imageCell}>
      <Image
        asset={image}
        alt={image.alt}
        width={600}
        key={`image-${image.asset?._ref ?? (3 * Math.random())}`}
      />
      {image.caption && <figcaption>{image.caption}</figcaption>}
    </div>
  );
  return (
    <>
      <div className={styles.swiperContainer}>
        <Swiper
          slidesPerView={1.2}
          keyboard={true}
          spaceBetween={32}
          speed={600}
          grabCursor={true}
        >
          {images.map((image) => (
            <SwiperSlide className={styles.swiperSlide}>
              <ImageCell image={image} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className={cn(
          styles.articleImagesContainer,
          styles[`column${images.length}`],
        )}
      >
        {images.map((image) => (
          <ImageCell image={image} />
        ))}
      </div>
    </>
  );
};
