import { Icon } from "@components";
import PropTypes from "prop-types";
import React from "react";

export const File = ({ value }) => {
  let { title, file } = value;
  if (!(file && title)) return null;
  let { url = "", extension = "" } = file.asset;

  return url ? (
    <span style={{ display: "block" }}>
      <a href={`${url}?dl=${title}.${extension}`}>
        <Icon symbol="file" size="18" />
        <span> {title}</span>
      </a>
    </span>
  ) : null;
};

File.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired,
  }),
};
