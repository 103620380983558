import { LanguageContextConsumer } from "@contexts";
import { cn } from "@lib";
import React from "react";

import * as styles from "./MapOverlay.module.scss";

export const MapCategoryToggle = ({ name, color, onClick, selected }) => {
  return (
    <LanguageContextConsumer>
      {({ localize }) => (
        <button
          className={cn(styles.mapToggle, selected && styles.selected)}
          onClick={onClick}
        >
          <div className={styles.circle} style={{ backgroundColor: color }} />
          <span>{localize(name)}</span>
        </button>
      )}
    </LanguageContextConsumer>
  );
};
