import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import { MapOverlay } from "./MapOverlay/MapOverlay";
import { Mapbox } from "./Mapbox";
import { Pins } from "./Pins/Pins";
import { Popup } from "./Popup/Popup";
import { colorizePins, colors } from "./mapUtils";

export const Map = ({
  width,
  height,
  scroll,
  zoom = false,
  pinData,
  center,
  withPopup,
  categories,
}) => {
  const [company, setCompany] = useState();
  const [popupInfo, setPopupInfo] = useState();
  const [selectedPinData, setSelectedPinData] = useState(
    (categories && []) || pinData,
  );
  const [selectedCategory, setSelectedCategory] = useState();
  pinData = categories ? colorizePins(pinData, categories) : pinData;

  const _onClickMarker = (company) => {
    setCompany(company);
    setPopupInfo(true);
  };

  const setFilter = useCallback(
    (filter) => {
      setPopupInfo(false);
      setSelectedPinData(
        pinData.filter((pin) =>
          pin.category.name.en.toLowerCase().includes(filter),
        ),
      );
      setSelectedCategory(filter);
    },
    [pinData],
  );

  useEffect(() => {
    categories && setFilter(categories.edges[0].node.name.en.toLowerCase());
  }, [categories]);

  return (
    <div style={{ height: "80vh" }}>
      <Mapbox
        width={width}
        height={height}
        scroll={scroll}
        center={center}
        zoom={zoom}
        setPopupInfo={setPopupInfo}
      >
        {(selectedPinData || pinData.length === 1) &&
          selectedPinData.map((pin, index) => (
            <Pins
              onClick={withPopup && _onClickMarker}
              key={`marker-${index}`}
              data={pin}
            />
          ))}
        {popupInfo && <Popup company={company} setPopupInfo={setPopupInfo} />}
        {categories && (
          <MapOverlay
            colors={colors}
            categories={categories}
            setFilter={setFilter}
            selectedCategory={selectedCategory}
          />
        )}
      </Mapbox>
    </div>
  );
};

Map.defaultProps = {
  width: "100vw",
  height: "80vh",
  scroll: false,
};

Map.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  scroll: PropTypes.bool,
  pinData: PropTypes.shape({ edges: PropTypes.array }),
  center: PropTypes.object,
  withPopup: PropTypes.bool,
  categories: PropTypes.shape({ edges: PropTypes.array }),
};
