import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

export function Seo({
  title: customTitle,
  ogImage,
  description: customDescription,
}) {
  const { sanitySiteConfig } = useStaticQuery(
    graphql`
      query {
        sanitySiteConfig(_id: { regex: "/global-config$/" }) {
          title
          description
        }
      }
    `,
  );

  let { title, description } = sanitySiteConfig || {};

  title = customTitle || title;
  description = customDescription || description;

  return (
    <Helmet title={title} titleTemplate={`%s – Oslobukta`}>
      <meta name="description" content={description} />
      <meta name="image" content={ogImage} />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta name="twitter:card" content="summary_large_image" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {ogImage && <meta name="twitter:image" content={ogImage} />}
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `no-NB`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};
