import { client, cn } from "@lib";
import { PortableText } from "@portabletext/react";
import PropTypes from "prop-types";
import React from "react";

import { wrapper } from "./BlockContent.module.scss";
import { serializers } from "./serializers";

const { projectId, dataset } = client.config();

export function BlockContent(props) {
  const { blocks, className } = props;

  if (!blocks) {
    console.error("Missing blocks");
    return null;
  }

  return (
    <div className={cn(wrapper, className)}>
      <PortableText
        value={blocks}
        // blocks={blocks}
        components={serializers}
      />
    </div>
  );
}

BlockContent.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};
