import PropTypes from "prop-types";
import React from "react";

export function EmbedHTML({ value }) {
  const { html } = value;
  if (!html) {
    return undefined;
  }
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

EmbedHTML.propTypes = {
  node: PropTypes.shape({
    html: PropTypes.string,
  }),
};
