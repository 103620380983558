import React from "react";

import { iconLibrary } from "./iconlibrary";

function whichIcon(symbol) {
  if (Object.getOwnPropertyNames(iconLibrary).includes(symbol)) {
    return iconLibrary[symbol];
  }
}

export function Icon({
  symbol,
  fill,
  strokeWidth,
  size,
  color,
  viewBox,
  ...props
}) {
  const iconStyle = {
    width: size,
    height: size,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || "0 0 28 26"}
      fill={fill ? color : "none"}
      shapeRendering="geometricPrecision"
      style={iconStyle}
      {...iconStyle}
      {...props}
    >
      <g stroke={fill ? "none" : color} strokeWidth={strokeWidth}>
        {whichIcon(symbol)}
      </g>
    </svg>
  );
}

Icon.defaultProps = {
  strokeWidth: 1,
  size: "32px",
  color: "currentColor",
};
