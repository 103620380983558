// extracted by mini-css-extract-plugin
export var Body1 = "Typography-module--Body1--35f6c";
export var Body2 = "Typography-module--Body2--7bd01";
export var Caption = "Typography-module--Caption--b4525";
export var Heading1 = "Typography-module--Heading1--6f9cb";
export var Heading2 = "Typography-module--Heading2--f2083";
export var Heading3 = "Typography-module--Heading3--08b2e";
export var Heading4 = "Typography-module--Heading4--bfdea";
export var Heading5 = "Typography-module--Heading5--490e9";
export var Heading6 = "Typography-module--Heading6--26eea";
export var Overline = "Typography-module--Overline--81125";
export var Subtitle1 = "Typography-module--Subtitle1--77b44";
export var Subtitle2 = "Typography-module--Subtitle2--278da";
export var alignCenter = "Typography-module--align-center--b8ed9";
export var alignLeft = "Typography-module--align-left--f3a97";
export var alignRight = "Typography-module--align-right--0cc60";
export var fontSans = "Typography-module--fontSans--aa4d2";
export var fontSerif = "Typography-module--fontSerif--dd242";
export var fontWeightBlack = "Typography-module--fontWeightBlack--dadf4";
export var fontWeightBold = "Typography-module--fontWeightBold--19db6";
export var fontWeightLight = "Typography-module--fontWeightLight--d99e6";
export var fontWeightMedium = "Typography-module--fontWeightMedium--ec385";
export var fontWeightRegular = "Typography-module--fontWeightRegular--5ad18";
export var large = "Typography-module--large--a54b8";
export var medium = "Typography-module--medium--3aeba";
export var small = "Typography-module--small--01b6f";
export var uppercase = "Typography-module--uppercase--d4b4b";
export var xlarge = "Typography-module--xlarge--b62ec";
export var xs = "Typography-module--xs--093a6";
export var xxlarge = "Typography-module--xxlarge--be12a";
export var xxs = "Typography-module--xxs--e54f5";
export var xxxlarge = "Typography-module--xxxlarge--d103a";
export var xxxs = "Typography-module--xxxs--475ed";