import { LanguageContext } from "@contexts";
import { Button } from "@ui/buttons";
import React, { useContext } from "react";

import * as styles from "../../../ui/buttons/Button/Button.module.scss";

export const CategoryToggle = ({ name, toggled, onClick }) => {
  const { localize } = useContext(LanguageContext);
  return (
    <Button onClick={onClick} size="small" className={toggled ? styles.inverse : ""}>
      {localize(name)}
    </Button>
  );
};
