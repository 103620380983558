import React from "react";

export const iconLibrary = {
  arrowBack: (
    <>
      <path d="M10.552 6L3 12m7.552 6L3 12m0 0h18" />
    </>
  ),
  arrowForward: (
    <>
      <path d="M1 12.5h34" />
      <path d="M24.777 1.357l10.866 10.842-11.47 11.444" />
    </>
  ),
  facebook: (
    <>
      
<path d="M21.74 0H4.97998C2.23998 0 0 2.1758 0 4.8406V21.147C0 23.824 2.23998 25.988 4.97998 25.988H21.74C24.49 25.988 26.7101 23.812 26.7101 21.147V4.8406C26.7101 2.1636 24.48 0 21.74 0ZM24.2101 21.147C24.2101 22.467 23.11 23.543 21.75 23.543H15.86V15.634H19.98C20.67 15.634 21.23 15.084 21.23 14.412C21.23 13.74 20.67 13.189 19.98 13.189H15.86V11.625C15.86 10.537 16.3001 10.195 16.4501 10.085C17.3301 9.412 19.1201 9.62 19.6901 9.742C20.3701 9.901 21.0501 9.498 21.2001 8.8378C21.3601 8.1777 20.95 7.5176 20.27 7.3587C19.93 7.2854 16.87 6.6253 14.89 8.1533C14.19 8.6911 13.3401 9.742 13.3401 11.613V13.177H11.5C10.81 13.177 10.24 13.727 10.24 14.4C10.24 15.072 10.81 15.622 11.5 15.622H13.3401V23.531H4.97998C3.61998 23.531 2.51001 22.455 2.51001 21.135V4.8406C2.51001 3.5205 3.61998 2.4448 4.97998 2.4448H21.74C23.09 2.4448 24.2001 3.5205 24.2001 4.8406V21.147H24.2101Z" fill="#294C72"/>


    </>
  ),
  instagram: (
    <>
  <path d="M21.73 26H4.96997C2.21997 26 0 23.824 0 21.159V4.8406C0 2.1636 2.22997 0 4.96997 0H21.73C24.48 0 26.71 2.1758 26.71 4.8406V21.147C26.71 23.824 24.47 25.988 21.73 25.988V26ZM4.96997 2.4448C3.60997 2.4448 2.51001 3.5205 2.51001 4.8406V21.147C2.51001 22.467 3.60997 23.543 4.96997 23.543H21.73C23.09 23.543 24.1901 22.467 24.1901 21.147V4.8406C24.1901 3.5205 23.09 2.4448 21.73 2.4448H4.96997Z" fill="#294C72"/>
  <path d="M13.35 19.472C9.67998 19.472 6.69006 16.563 6.69006 12.994C6.69006 9.42497 9.67998 6.51526 13.35 6.51526C17.02 6.51526 20 9.42497 20 12.994C20 16.563 17.01 19.472 13.35 19.472ZM13.35 8.97216C11.07 8.97216 9.20996 10.781 9.20996 13.006C9.20996 15.231 11.07 17.04 13.35 17.04C15.64 17.04 17.49 15.231 17.49 13.006C17.49 10.781 15.63 8.97216 13.35 8.97216Z" fill="#294C72"/>
  <path d="M20.8101 7.13873C21.5601 7.13873 22.16 6.55313 22.16 5.83083C22.16 5.10843 21.5601 4.52283 20.8101 4.52283C20.0701 4.52283 19.47 5.10843 19.47 5.83083C19.47 6.55313 20.0701 7.13873 20.8101 7.13873Z" fill="#294C72"/>
    </>
  ),
  linkedin: (
    <>
    
    
<path d="M22.01 26H5.25C2.5 26 0.280029 23.824 0.280029 21.159V4.8406C0.280029 2.1636 2.51 0 5.25 0H22.01C24.77 0 26.99 2.1758 26.99 4.8406V21.147C26.99 23.824 24.75 25.988 22.01 25.988V26ZM5.27002 2.4448C3.91002 2.4448 2.80005 3.5205 2.80005 4.8406V21.147C2.80005 22.467 3.91002 23.543 5.27002 23.543H22.03C23.38 23.543 24.49 22.467 24.49 21.147V4.8406C24.49 3.5205 23.38 2.4448 22.03 2.4448H5.27002Z" fill="#294C72"/>
<path d="M8.80005 20.7441C8.11005 20.7441 7.54004 20.1941 7.54004 19.5211V11.3561C7.54004 10.6831 8.11005 10.1331 8.80005 10.1331C9.49005 10.1331 10.05 10.6831 10.05 11.3561V19.5211C10.05 20.1941 9.49005 20.7441 8.80005 20.7441Z" fill="#294C72"/>
<path d="M8.80005 8.61773C9.54005 8.61773 10.14 8.03213 10.14 7.30983C10.14 6.58743 9.54005 6.00183 8.80005 6.00183C8.05005 6.00183 7.45007 6.58743 7.45007 7.30983C7.45007 8.03213 8.05005 8.61773 8.80005 8.61773Z" fill="#294C72"/>
<path d="M19.3501 20.744C18.6601 20.744 18.1001 20.194 18.1001 19.521V14.253C18.1001 13.324 17.32 12.578 16.37 12.578C15.43 12.578 14.65 13.336 14.65 14.253V19.521C14.65 20.194 14.09 20.744 13.4 20.744C12.71 20.744 12.14 20.194 12.14 19.521V14.253C12.14 11.979 14.04 10.134 16.37 10.134C18.71 10.134 20.61 11.979 20.61 14.253V19.521C20.61 20.194 20.0401 20.744 19.3501 20.744Z" fill="#294C72"/>


    </>
  ),
  calendar: (
    <>
      <path d="M21.558 5.61h-3.186v.895h2.743v2.327H2.885V6.505h3.894v1.61h.885V4h-.885v1.61H2.442L2 6.059v14.495l.442.447h19.116l.442-.447V6.058l-.442-.447zM2.885 20.106V9.726h18.23v10.38H2.885zm13.451-11.99h.885V4h-.885v1.61H8.814v.895h7.522v1.61z" />
    </>
  ),
  news: (
    <>
      <path d="M21.474 4h-1.895l-.169.02L3.053 9.404V8.26H2v7.48h1.053v-1.143l4.631 1.517v1.496l.358.5 5.684 1.87.695-.5v-1.142l4.99 1.641.168.021h1.895l.526-.52V4.52L21.474 4zm-8.106 14.753l-4.631-1.517v-.768l4.631 1.516v.77zm7.58.208h-1.285l-16.61-5.465v-2.992l16.61-5.465h1.284v13.922z" />
    </>
  ),
  info: (
    <>
      <path d="M12 22C6.484 22 2 17.516 2 12S6.484 2 12 2s10 4.484 10 10-4.484 10-10 10zm0-18.947c-4.926 0-8.947 4.02-8.947 8.947 0 4.926 4.02 8.947 8.947 8.947 4.926 0 8.947-4.02 8.947-8.947 0-4.926-4.02-8.947-8.947-8.947zm0 3.536c.379 0 .674.295.674.674a.665.665 0 01-.674.674.665.665 0 01-.674-.674c0-.379.295-.674.674-.674zm-.526 3.243h1.052v7.578h-1.052V9.832z" />
    </>
  ),
  search: (
    <>
      <path
        clipRule="evenodd"
        d="M16.54 20.802a8.056 8.056 0 10-6.302-14.828 8.056 8.056 0 006.302 14.828z"
      />
      <path d="M19.086 19.086l6.248 6.249" />
    </>
  ),
  circleClose: (
    <>
      <circle cx="12" cy="12" r="11" />
      <path d="M6.25 6.5L17.75 18m0-11.5L6.25 18" />
    </>
  ),
  close: (
    <>
      <path d="M6.944 5.5L18.5 17.056 17.056 18.5 5.5 6.944 6.944 5.5z" />
      <path d="M18.5 6.944L6.944 18.5 5.5 17.056 17.056 5.5 18.5 6.944z" />
    </>
  ),
  hamburger: (
    <>
      <path d="M4 6.855h16v1.959H4zM4 10.773h16v1.959H4zM4 14.691h16v1.959H4z" />
    </>
  ),
  file: (
    <>
      <path d="M19.625 7.825V21H5V3h9.8l4.825 4.825zm-4.5-.325H17.7l-2.575-2.575V7.5zM18.5 19.875V8.625H14v-4.5H6.125v15.75H18.5z" />
    </>
  ),
  filter: (
    <>
      <path
        clipRule="evenodd"
        d="M5 4h14.492l-5.583 7.954v7.033L10.584 21v-9.046L5 4z"
      />
    </>
  ),
  map: (
    <>
      <path d="M12 22.5503L17.657 16.8933C18.7758 15.7744 19.5377 14.349 19.8463 12.7971C20.155 11.2453 19.9965 9.63679 19.391 8.17501C18.7855 6.71322 17.7601 5.46382 16.4445 4.58479C15.1289 3.70575 13.5822 3.23657 12 3.23657C10.4178 3.23657 8.87107 3.70575 7.55548 4.58479C6.2399 5.46382 5.21452 6.71322 4.60901 8.17501C4.00349 9.63679 3.84504 11.2453 4.15369 12.7971C4.46234 14.349 5.22422 15.7744 6.343 16.8933L12 22.5503Z" />
      <path d="M15 11.2363C15 12.032 14.6839 12.795 14.1213 13.3576C13.5587 13.9203 12.7956 14.2363 12 14.2363C11.2044 14.2363 10.4413 13.9203 9.87868 13.3576C9.31607 12.795 9 12.032 9 11.2363C9 10.4407 9.31607 9.67762 9.87868 9.11501C10.4413 8.5524 11.2044 8.23633 12 8.23633C12.7956 8.23633 13.5587 8.5524 14.1213 9.11501C14.6839 9.67762 15 10.4407 15 11.2363V11.2363Z" />
    </>
  ),
  mapMarker: (
    <path d="M6.06 4.86a8.4 8.4 0 0 1 11.88 11.88L12 22.68l-5.94-5.94a8.4 8.4 0 0 1 0-11.88ZM12 13.2a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Z" />
  ),
  externalLink: (
    <path d="M14 4H20M20 4V9.85953M20 4L10 13.7659M10 5.95318H4L4.00002 19.6254H18V13.7659L10 5.95318Z" />
  ),
};
