import logo from "@assets/logo/oslobukta_logo.png";
import pattern1 from "@assets/logo/Pattern1.png";
import pattern2 from "@assets/logo/Pattern2.png";
import pattern3 from "@assets/logo/Pattern3.png";
import pattern4 from "@assets/logo/Pattern4.png";
import pattern5 from "@assets/logo/Pattern5.png";
import pattern6 from "@assets/logo/Pattern6.png";
import whiteLogo from "@assets/logo/White.svg";
import PropTypes from "prop-types";
import React, { useState } from "react";

export const Logo = ({ white, className, noHover }) => {

  return ( 
        <div className={className}>
      <img src={logo} alt="Oslobukta logo" width={340} height={72} />
    </div>
  )
};

Logo.defaultProps = {
  white: false,
};

Logo.propTypes = {
  white: PropTypes.bool,
  className: PropTypes.string,
  noHover: PropTypes.bool,
};
