export const colors = [
  "var(--color-algae)",
  "var(--color-sky)",
  "var(--color-forest)",
  "var(--color-orange)",
  "var(--color-purple)",
  "var(--color-red)",
  "var(--color-forest)",
];

export const colorizePins = (companies, categories) => {
  const categoryLabels = categories.edges.map(
    ({ node: category }) => category.name.nb
  );

  return companies.edges
    .filter(({ node: company }) => company.category)
    .map(({ node: company }) => {
      let pinColor = colors[categoryLabels.indexOf(company.category.name.nb)];
      return { pinColor, ...company };
    });
};
