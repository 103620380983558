import React from "react";

export const StandardPaddingWrapper = ({ children, className }) => {
  return (
    <div
      className={className}
      style={{
        padding:
          "var(--standard-padding) var(--standard-padding) calc(var(--standard-padding) + 3rem) var(--standard-padding)",
      }}
    >
      {children}
    </div>
  );
};
