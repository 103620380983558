import { Icon } from "@components";
import { cn } from "@lib";
import { Link } from "gatsby";
import React, { ReactNode } from "react";
import * as styles from "./Button.module.scss";

interface ButtonProps {
  children: ReactNode;
  className?: string;
  onClick?: any;
  type?: "button" | "submit" | "reset";
  secondary?: boolean;
  outlined?: boolean;
  inverted?: boolean;
  href?: string;
  withArrow?: boolean;
  autoWidth?: boolean;
  size?: "small" | "medium" | "large"
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  onClick,
  type = "button",
  secondary,
  outlined,
  inverted,
  href,
  withArrow,
  autoWidth,
  size = "large",
}) => {
  const buttonClasses = cn(
    size === "small"
      ? styles.buttonSmall
      : size === "medium"
        ? styles.buttonMedium
        : styles.button,
    secondary ? styles.secondary : "",
    outlined ? styles.outlined : "",
    inverted ? styles.inverse : "",
    autoWidth ? styles.autoWidth : "",
    className,
  );

  const Arrow = () => (
    <Icon symbol="arrowForward" viewBox="0 0 36 24" strokeWidth={2} size={24} />
  );

  return href ? (
    href.startsWith("/") ? (
      <Link to={href} className={buttonClasses}>
        {children}
        {withArrow && <Arrow />}
      </Link>
    ) : (
      <a href={href} className={buttonClasses}>
        {children}
        {withArrow && <Arrow />}
      </a>
    )
  ) : (
    <button
      onClick={(e) => onClick && onClick(e)}
      className={buttonClasses}
      type={type}
    >
      {children}
      {withArrow && <Arrow />}
    </button>
  );
};
