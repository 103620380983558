import { leadingZero } from "@lib";

export const datesAreOnSameDay = (startTime: Date, endTime: Date) => {
  if (!endTime) return false;
  return (
    startTime.getFullYear() === endTime.getFullYear() &&
    startTime.getMonth() === endTime.getMonth() &&
    startTime.getDate() === endTime.getDate()
  );
};

export const getDateWithLeadingZero = (time) => leadingZero(time.getDate());

export const getDateString = (startTime: string, endTime: string) => {
  if (!startTime) return null;
  const start = new Date(startTime);
  const end = new Date(endTime);
  if (!endTime) {
    return start.toLocaleDateString("no-nb", {
      day: "2-digit",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  if (datesAreOnSameDay(start, end)) {
    return (
      start.toLocaleDateString("no-nb", {
        day: "2-digit",
        month: "long",
        hour: "2-digit",
        minute: "2-digit",
      }) +
      " - " +
      end.toLocaleTimeString("no-nb", { hour: "2-digit", minute: "2-digit" })
    );
  } else {
    if (start.getMonth() !== end.getMonth()) {
      return (
        start.toLocaleDateString("no-nb", { day: "2-digit", month: "long" }) +
        " - " +
        end.toLocaleDateString("no-nb", { day: "2-digit", month: "long" })
      );
    }
    return (
      start.toLocaleDateString("no-nb", { day: "2-digit" }) +
      "-" +
      end.toLocaleDateString("no-nb", { day: "2-digit", month: "long" })
    );
  }
};
