// youtube.js
import getYouTubeId from "get-youtube-id";
import PropTypes from "prop-types";
import React from "react";
import YouTube from "react-youtube";

import * as styles from "./Video.module.scss";

export const Video = ({ value }) => {
  const { _type: type, url } = value;
  let id;
  if (type === "youtube") {
    // Youtube
    id = getYouTubeId(url);
    return <YouTube videoId={id} className={styles.youtube} />;
  } else {
    // Vimeo
    const parts = url.split("/");
    id = parts[parts.length - 1];
    return (
      id && (
        <iframe
          title="vimeo-player"
          src={`https://player.vimeo.com/video/${id}`}
          width="100%"
          height="360"
          frameBorder="0"
          allowFullScreen
        />
      )
    );
  }
};

Video.propTypes = {
  node: PropTypes.shape({
    _type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};
