import { Icon } from "@components";
import { LanguageContextConsumer } from "@contexts";
import { cn } from "@lib";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";

import { colors } from "../mapUtils";
import { MapCategoryToggle } from "./MapCategoryToggle";
import * as styles from "./MapOverlay.module.scss";

export const MapOverlay = ({ categories, setFilter, selectedCategory }) => {
  const [open, setOpen] = useState(false);

  const toggleOverlay = () => {
    setOpen(!open);
  };

  const setCategory = (name) => {
    setFilter(name.en.toLowerCase());
    setOpen(false);
  };

  return (
    <LanguageContextConsumer>
      {({ localize }) => (
        <div className={cn(styles.mapOverlay, open && styles.open)}>
          <button onClick={toggleOverlay} className={styles.title}>
            <h2>
              {localize({ nb: "Utforsk området", en: "Explore the area" })}
            </h2>
            <div className={styles.close}>
              <Icon
                symbol={open ? "close" : "filter"}
                fill={open ? "#FFFDF8" : false}
                size="32"
              />
            </div>
          </button>
          <div className={styles.toggles}>
            {categories.edges.map(({ node: { name } }, index) => (
              <MapCategoryToggle
                key={`map-category-${index}`}
                name={name}
                color={colors[index]}
                selected={selectedCategory === name.en.toLowerCase()}
                onClick={() => setCategory(name)}
              />
            ))}
          </div>
        </div>
      )}
    </LanguageContextConsumer>
  );
};

MapOverlay.propTypes = {
  categories: PropTypes.shape({ edges: PropTypes.array }),
  setFilter: PropTypes.func,
  selectedCategory: PropTypes.string,
};
