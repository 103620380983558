import { Button } from "@ui/buttons";
import React from "react";

import { InternalLink } from "../../lib/InternalLink";
import { ArticleImages } from "./ArticleImages/ArticleImages";
import { EmbedHTML } from "./EmbedHTML";
import { Figure } from "./Figure";
import { File } from "./File";
import { TwoColumnImage } from "./TwoColumnImage/TwoColumnImage";
import { Video } from "./Video";

export const serializers = {
  types: {
    articleImages: ArticleImages,
    embedHTML: EmbedHTML,
    figure: Figure,
    youtube: Video,
    vimeo: Video,
    fileUpload: File,
    image2x1: TwoColumnImage,
    button: ({ value: { label, href } }) => (
      <section>
        <Button withArrow href={href}>
          {label}
        </Button>
      </section>
    ),
  },
  marks: {
    internalLink: InternalLink,
    link: ({ value, children }) => {
      return <a href={value.href}>{children}</a>;
    },
  },
};
