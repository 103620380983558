const categories = {
  food: "fb085724-51c4-4b30-bb4e-d744e5e48332",
  shopping: "135c7dbe-5ad0-4b53-8a74-ab11997b1675",
  service: "5a1eb148-0c61-464b-ba51-6e0b0c7a06e1",
  health: "c6e26f16-50f7-491a-8e00-6a97e21e92de",
  culture: "55cc1264-27ad-41bf-9527-4c9591997339",
};

export const setCompanyCssVariables = (categoryId) => {
  const categoryNames = Object.fromEntries(
    Object.entries(categories).map((a) => a.reverse()),
  );

  const currentCategory = categoryNames[categoryId];

  const backgroundColor = {
    food: "var(--color-forest)",
    shopping: "var(--color-pink)",
    health: "var(--color-sky)",
    culture: "var(--color-algae)",
  }[currentCategory];

  return {
    "--category-color": backgroundColor,
    "--company-tag-text-color":
      currentCategory === "food" ? "#FFFDF8" : "var(--color-black)",
  };
};
