import { client, urlFor } from "@lib";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import React from "react";

export const Image: React.FC<{
  asset: any;
  width: number;
  height?: number;
  style?: React.CSSProperties;
  alt: string;
  position?: "absolute" | "relative";
  aspectRatio?: number;
  className?: string;
}> = (props) => {
  const {
    asset: image,
    width,
    height,
    alt,
    style,
    position = "relative",
    aspectRatio,
    className,
    ...imageProps
  } = props;
  const { x, y } = (image && image.hotspot) || {};

  if (!image) return null;
  if (!image.asset) return null;

  const objectPosition =
    x && y ? `${Math.round(x * 100)}% ${Math.round(y * 100)}%` : "50% 50%";

  const AspectRatioWrapper = ({ children }) => {
    if (!aspectRatio) return children;
    return (
      <div
        style={{
          position: position,
          width: "100%",
          paddingTop: (1 / aspectRatio) * 100 + "%",
        }}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            style: {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          });
        })}
      </div>
    );
  };

  if (image.asset) {
    return (
      <AspectRatioWrapper>
        <GatsbyImage
          image={
            image.asset.gatsbyImageData
              ? image.asset.gatsbyImageData
              : getGatsbyImageData(
                image.asset.id,
                { maxWidth: 1024 },
                client.config(),
              )
          }
          objectFit="cover"
          className={className}
          objectPosition={objectPosition}
          alt={alt}
          {...imageProps}
        />
      </AspectRatioWrapper>
    );
  } else if (image.asset.url) {
    return (
      <AspectRatioWrapper>
        <img
          alt={alt}
          src={urlFor(image).width(width).height(height).url()}
          style={{ objectPosition }}
          {...imageProps}
          className={className}
        />
      </AspectRatioWrapper>
    );
  } else {
    return null;
  }
};

Image.defaultProps = {
  width: 2000,
};
