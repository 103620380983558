import { Icon, Logo } from "@components";
import { DarkModeContext, LanguageContext } from "@contexts";
import { cn } from "@lib";
import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useContext, useEffect, useState } from "react";

import { LanguageSwitch } from "./LanguageSwitch/LanguageSwitch";
import * as styles from "./Nav.module.scss";
import { NavItem } from "./NavItem";


export const Nav = ({ hideNav }) => {
  const [showNav, setShowNav] = useState(false);
  const [fadeAway, setFadeAway] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);
  const { localize } = useContext(LanguageContext);

  const {
    sanitySiteConfig: { navUrls },
  } = useStaticQuery(graphql`
    query {
      sanitySiteConfig(_id: { regex: "/global-config$/" }) {
        navUrls {
          href
          name {
            en
            nb
          }
        }
      }
    }
  `);

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    function onScroll() {
      let currentScrollPos = window.pageYOffset;
      setFadeAway(prevScrollpos <= currentScrollPos && currentScrollPos > 50);
      prevScrollpos = currentScrollPos;
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const hideMenu = () => {
    setShowNav(false);
  };

  const handleMenuToggle = () => {
    setShowNav(!showNav);
  };

  // const toggleSearch = () => {
  //   setShowSearch(!showSearch);
  // };

  const links = [{ label: {} }];

  return (
    !hideNav && (
      <>
        <nav
          className={cn(
            styles.nav,
            isDarkMode ? styles.black : "",
            fadeAway ? styles.fade : "",
          )}
        >
          <div className={styles.container}>
            <Link to="/" className={styles.logo}>
              <Logo white={isDarkMode} />
            </Link>
            <button
              className={styles.hamburger}
              onClick={handleMenuToggle}
              aria-label="Hamburger menu"
            >
              {!showNav ? (
                <Icon color={"#294C72"} symbol="hamburger" fill size="3rem" />
              ) : (
                <Icon color={"#294C72"} symbol="close" fill size="3rem" />
              )}
            </button>
            <ul className={cn(styles.items, showNav && styles.show)}>
              <ul className={styles.pageLinks}>
                {navUrls.map(({ name, href }) => (
                  <NavItem
                    key={href}
                    name={localize(name)}
                    href={href}
                    onClick={hideMenu}
                  />
                ))}
              </ul>
              <ul className={styles.navTools}>
                <li className={styles.languageSwitch}>
                  <LanguageSwitch />
                </li>
              </ul>
            </ul>
          </div>
        </nav>
      </>
    )
  );
};
