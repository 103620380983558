import { Image } from "@components";
import { cn } from "@lib";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./TwoColumnImage.module.scss";

export const TwoColumnImage = ({ value }) => {
  const customGrid = value.ratio || 50;
  const images = [value.imageLeft, value.imageRight];
  return (
    <div
      className={cn(styles.grid, styles[`grid${images.length}`])}
      style={{ gridTemplateColumns: `${customGrid}fr ${100 - customGrid}fr` }}
    >
      {images.map((image, index) => {
        if (image && image.asset) {
          return (
            <div key={`image-${index}`}>
              <Image
                asset={image}
                width={
                  670 * 1.5 * index === 0
                    ? customGrid / 100
                    : (100 - customGrid) / 100
                }
                className={styles.image}
              />
            </div>
          );
        } else {
          return <div key={`image-${index}`} />;
        }
      })}
    </div>
  );
};

TwoColumnImage.propTypes = {
  node: PropTypes.shape({
    imageLeft: PropTypes.object,
    imageRight: PropTypes.object,
    ratio: PropTypes.number,
  }),
};
