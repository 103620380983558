import { Icon } from "@components";
import { Link } from "gatsby";
import React from "react";
import { Popup as MapboxPopup } from "react-map-gl";

import * as styles from "./Popup.module.scss";

export const Popup = ({ company, setPopupInfo }) => {
  return (
    <MapboxPopup
      longitude={company.location.lng}
      latitude={company.location.lat}
      className={styles.popup}
      offsetTop={-35}
    >
      <Link to={`/oppdag/${company.slug.current}`}>
        <div
          className={styles.innerPoup}
          style={{
            backgroundColor: company.pinColor,
          }}
        >
          <span>{company.name}</span>
          {company.slug && <Icon symbol="arrowForward" viewBox="0 0 36 24" />}
        </div>
      </Link>
    </MapboxPopup>
  );
};
