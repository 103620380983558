import React, { useState } from "react";

const DarkModeContext = React.createContext();

const DarkModeContextProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const toggleDarkMode = (value) => {
    setIsDarkMode(value);
    document.body.style.backgroundColor = value ? "#000" : "#FFFDF8";
  };
  return (
    <DarkModeContext.Provider
      value={{ isDarkMode, setIsDarkMode: toggleDarkMode }}
    >
      {children}
    </DarkModeContext.Provider>
  );
};

export { DarkModeContextProvider, DarkModeContext };
