import { Link } from "gatsby";
import React from "react";

import { useLocation } from "@reach/router"; // Import useLocation hook

import * as styles from "./MultiplyTexHover.module.scss";

interface MultiplyTexHoverProps {
  name: string;
  href: string;
  onClick?: () => void;
}

export const NavLink: React.FC<MultiplyTexHoverProps> = ({ name, href, onClick }) => {
  const { pathname } = useLocation();
  const isActive = pathname === href + "/";




  return (
    <Link to={href} onClick={onClick}>
      <div style={{ fontWeight: isActive && 500 }} className={styles.main}>{name}</div>
    </Link>
  );
};
