import { Footer, Nav } from "@components";
import { DarkModeContextProvider } from "@contexts";
import { LanguageContextProvider } from "@contexts";
import { cn } from "@lib";
import React from "react";

import * as styles from "./Layout.module.scss";
import { AllStuffMainProvider } from "src/contexts/AllStuffMainContext";

function Layout({ children, standardPadding, className }) {
  return (
    <AllStuffMainProvider>
      <LanguageContextProvider>
        <DarkModeContextProvider>
          <div className={styles.container}>
            <Nav />
            <div
              className={cn(
                styles.content,
                standardPadding && styles.standardPadding,
                className,
              )}
            >
              {children}
            </div>
            <Footer />
          </div>
        </DarkModeContextProvider>
      </LanguageContextProvider>
    </AllStuffMainProvider>
  );
}

export default Layout;
