exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gavekort-tsx": () => import("./../../../src/pages/gavekort.tsx" /* webpackChunkName: "component---src-pages-gavekort-tsx" */),
  "component---src-pages-hva-skjer-tsx": () => import("./../../../src/pages/hva-skjer.tsx" /* webpackChunkName: "component---src-pages-hva-skjer-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-join-oslobukta-tsx": () => import("./../../../src/pages/join-oslobukta.tsx" /* webpackChunkName: "component---src-pages-join-oslobukta-tsx" */),
  "component---src-pages-kart-js": () => import("./../../../src/pages/kart.js" /* webpackChunkName: "component---src-pages-kart-js" */),
  "component---src-pages-leie-js": () => import("./../../../src/pages/leie.js" /* webpackChunkName: "component---src-pages-leie-js" */),
  "component---src-pages-lokaler-js": () => import("./../../../src/pages/lokaler.js" /* webpackChunkName: "component---src-pages-lokaler-js" */),
  "component---src-pages-nabo-js": () => import("./../../../src/pages/nabo.js" /* webpackChunkName: "component---src-pages-nabo-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-oppdag-js": () => import("./../../../src/pages/oppdag.js" /* webpackChunkName: "component---src-pages-oppdag-js" */),
  "component---src-pages-personvern-js": () => import("./../../../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-templates-arrangement-event-js": () => import("./../../../src/templates/arrangement/event.js" /* webpackChunkName: "component---src-templates-arrangement-event-js" */),
  "component---src-templates-company-company-js": () => import("./../../../src/templates/company/company.js" /* webpackChunkName: "component---src-templates-company-company-js" */),
  "component---src-templates-nyhet-news-js": () => import("./../../../src/templates/nyhet/news.js" /* webpackChunkName: "component---src-templates-nyhet-news-js" */),
  "component---src-templates-oppdag-oppdag-js": () => import("./../../../src/templates/oppdag/oppdag.js" /* webpackChunkName: "component---src-templates-oppdag-oppdag-js" */)
}

