import { Icon } from "@components";
import { LanguageContext } from "@contexts";
import { Body2, Heading5, Heading6, Overline } from "@ui/typography";
import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import * as styles from "./Footer.module.scss";

export const Footer = () => {
  const {
    sanitySiteConfig: { footerUrls },
    sanityContact: { contactInfo },
  } = useStaticQuery(graphql`
    query {
      sanitySiteConfig(_id: { regex: "/global-config$/" }) {
        footerUrls {
          label {
            nb
            en
          }
          url
        }
      }
      sanityContact(_id: { regex: "/contact$/" }) {
        contactInfo: _rawContactInfo
      }
    }
  `);
  const { localize } = useContext(LanguageContext);
  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        <div className={styles.links}>
          {footerUrls.map((link, index) => {
            const content = () => <Heading6 fontSize="medium">{localize(link.label)}</Heading6>;
            return !link.url.startsWith("/") ? (
              <a href={link.url} key={index} className={styles.link}>
                {content()}
              </a>
            ) : (
              <Link to={link.url} key={index} className={styles.link}>
                {content()}
              </Link>
            );
          })}
        </div>
        <div className={styles.social}>
          <Heading5 fontSize="xs" fontWeight="fontWeightBold" >{localize({ nb: "Følg oss", en: "Follow us" })}</Heading5>
          <div>
            <a
              className={styles.socialLink}
              href="https://www.facebook.com/oslobukta"
              aria-label="Facebook"
              rel="noreferrer"
              target="_blank"
            >
              <Icon symbol="facebook" fill color="#294C72" />
            </a>
            <a
              className={styles.socialLink}
              href="https://www.instagram.com/oslobukta/"
              aria-label="Instagram"
              rel="noreferrer"
              target="_blank"
            >
              <Icon symbol="instagram" fill color="#294C72" />
            </a>
            <a
              className={styles.socialLink}
              href="https://www.linkedin.com/showcase/oslobukta/?viewAsMember=true"
              aria-label="Linkedin"
              rel="noreferrer"
              target="_blank"
            >
              <Icon symbol="linkedin" fill color="#294C72" />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        {contactInfo.map(({ label, value }) => (
          <Body2 fontWeight="fontWeightRegular" fontSize="small" sans key={`contact-email-${value}`}>
            <strong>{localize(label)}</strong>
            <br />
            {value}
          </Body2>
        ))}
        <div className={styles.copyright}>
          <Body2 fontWeight="fontWeightRegular" fontSize="small" sans>
            COPYRIGHT © Oslobukta  |  website BY  <a href="https://umble.no">UMBLE </a>
          </Body2>
        </div>
      </div>

    </footer>
  );
};
