const imageUrlBuilder = require("@sanity/image-url");
const client = require("./client");

const urlFor = (source) => {
  const builder = imageUrlBuilder(client);
  return builder.image(source);
};

const cn = (...classes) => {
  return classes.join(" ");
};

const leadingZero = (s) => {
  return ("0" + s).slice(-2);
};

const daysUntil = (date) =>
  Math.ceil(
    (new Date(date).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
  );

const toDateString = (date) => {
  date = new Date(date);
  return `${leadingZero(date.getDate())}.${leadingZero(
    date.getMonth() + 1,
  )}.${date.getFullYear()}`;
};

const toTimeString = (date) => {
  return `${leadingZero(date.getHours())}:${leadingZero(date.getMinutes())}`;
};

const getMonthFromString = (monthString) => {
  switch (monthString) {
    case "Januar":
      return 0;
    case "Februar":
      return 1;
    case "Mars":
      return 2;
    case "April":
      return 3;
    case "Mai":
      return 4;
    case "Juni":
      return 5;
    case "Juli":
      return 6;
    case "August":
      return 7;
    case "September":
      return 8;
    case "Oktober":
      return 9;
    case "November":
      return 10;
    case "Desember":
      return 11;
    default:
      return null;
  }
};

const renderTime = (startTime, endTime) => {
  startTime = startTime && new Date(startTime);
  endTime = endTime && new Date(endTime);

  if (startTime && endTime) {
    return toDateString(startTime) === toDateString(endTime)
      ? `${toDateString(startTime)} kl. ${toTimeString(
          startTime,
        )}-${toTimeString(endTime)}`
      : `${toDateString(startTime)} kl. ${toTimeString(
          startTime,
        )} - ${toDateString(endTime)} kl. ${toTimeString(endTime)}`;
  } else if (startTime) {
    return `${toDateString(startTime)} kl. ${toTimeString(startTime)}`;
  } else {
    return "";
  }
};

const sortArticles = (articles) =>
  articles &&
  articles.sort((a, b) => {
    const aDate = a.node.startTime
      ? new Date(a.node.startTime)
      : new Date(a.node.publishDate);
    const bDate = b.node.startTime
      ? new Date(b.node.startTime)
      : new Date(b.node.publishDate);
    return aDate > bDate ? -1 : 1;
  });

const getArticleUrl = (type, slug) =>
  type === "event" ? "/arrangement/" + slug : "/nyhet/" + slug;

const mapLink = (name, information) => {
  let { street, postalCode, city } = information;
  const linkPart = [];
  name && linkPart.push(name.replace(" ", "+"));
  street && linkPart.push(street.replace(" ", "+"));
  postalCode && linkPart.push(postalCode.replace(" ", "+"));
  city && linkPart.push(city.replace(" ", "+"));
  return `https://www.google.com/maps/search/?api=1&query=${linkPart.join(
    "%2C",
  )}`;
};

const isPastEvent = (startTime, endTime) => {
  if (typeof startTime !== typeof Date) {
    startTime = new Date(startTime);
  }
  if (typeof endTime !== typeof Date) {
    endTime = new Date(endTime);
  }
  const now = new Date().getTime();
  if (startTime && endTime) {
    return endTime.getTime() < now;
  }
  return startTime.getTime() < now;
};

const slugify = (string) => {
  return string
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

module.exports = {
  urlFor,
  cn,
  leadingZero,
  toDateString,
  toTimeString,
  renderTime,
  sortArticles,
  getArticleUrl,
  daysUntil,
  getMonthFromString,
  mapLink,
  isPastEvent,
  slugify,
};
