// extracted by mini-css-extract-plugin
export var black = "Nav-module--black--c20ff";
export var container = "Nav-module--container--e6e00";
export var fade = "Nav-module--fade--f6eaf";
export var hamburger = "Nav-module--hamburger--7f06c";
export var items = "Nav-module--items--c539d";
export var languageSwitch = "Nav-module--languageSwitch--e8185";
export var logo = "Nav-module--logo--3fc22";
export var nav = "Nav-module--nav--0608c";
export var navTools = "Nav-module--navTools--edfe2";
export var on = "Nav-module--on--d8acf";
export var pageLinks = "Nav-module--pageLinks--c0c4b";
export var scrim = "Nav-module--scrim--21f6d";
export var search = "Nav-module--search--d4ad0";
export var searchHover = "Nav-module--searchHover--9a88c";
export var show = "Nav-module--show--b1669";