import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

export const InternalLink = ({ value, children }) => {
  if (!value.reference) return children;
  const urls = {
    company: (url) => `/oppdag/${url}`,
    event: (url) => `/arrangement/${url}`,
    news: (url) => `/nyhet/${url}`,
    banquetFacility: (url) => `/selskapslokale/${url}`,
  };
  const { slug = {}, _type = "" } = value.reference;
  const generateUrl = urls[_type];
  const href = (generateUrl && generateUrl(slug.current)) || null;
  return href ? <Link to={href}>{children}</Link> : children;
};

InternalLink.propTypes = {
  value: PropTypes.object,
  children: PropTypes.any,
};
