import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

const languages = [
  { id: "nb", title: "Norsk", isDefault: true },
  { id: "en", title: "English" },
];

const LanguageContext = React.createContext();

const LanguageContextProvider = ({ children }) => {
  const lang = languages
    .sort((a, b) => (a.isDefault ? -1 : 1))
    .map((lang) => lang.id);
  const [selectedLanguage, setSelectedLanguage] = useState(lang[0]);
  const [langList, setLangList] = useState(lang);

  // Get selected language, uses fallback languages if available
  const localize = (text) => {
    if (!text) return "";
    for (let l of langList) {
      if (text[l]) return text[l];
    }
    return ""; // If no languages are available
  };

  // Function to change language, used in languageSwitch
  const changeLanguage = useCallback(
    (newLang) => {
      setSelectedLanguage(newLang);
      setLangList(langList.sort((a, b) => (a === newLang ? -1 : 1)));
      localStorage.setItem("lang", newLang);
    },
    [langList]
  );

  // Update language on first load
  useEffect(() => {
    const localLang = localStorage.getItem("lang") || lang[0];
    changeLanguage(localLang);
  }, [lang, changeLanguage]);

  // Return the actual context provider
  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        langList,
        changeLanguage,
        localize,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

LanguageContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

const LanguageContextConsumer = LanguageContext.Consumer;

export { LanguageContextProvider, LanguageContextConsumer, LanguageContext };
