import React, { FC, useEffect, useState } from "react";
import { cn } from "@lib";
import * as styles from "./Typography.module.scss";

interface HeadingProps {
  className?: string;
  align?: "left" | "center" | "right" | "justify";
  serif?: boolean;
  uppercase?: boolean;
  children: React.ReactNode;
  fontSize?:
  | "xxxs"
  | "xxs"
  | "xs"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge"
  | "xxxlarge";
  fontWeight?:
  | "fontWeightLight"
  | "fontWeightRegular"
  | "fontWeightMedium"
  | "fontWeightBold"
  | "fontWeightBlack";
}

interface BodyProps {
  className?: string;
  sans?: boolean;
  serif?: boolean;
  align?: "left" | "center" | "right" | "justify";
  fontSize?:
  | "xxxs"
  | "xxs"
  | "xs"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";
  fontWeight?:
  | "fontWeightLight"
  | "fontWeightRegular"
  | "fontWeightMedium"
  | "fontWeightBold"
  | "fontWeightBlack";
  fontType?: "serif" | "sans" | "mono";
  limitChars?: number;
  children: React.ReactNode;
}

const withHeadingProps = (Component: FC<HeadingProps>): FC<HeadingProps> => (props) => {
  const { className, align, serif, uppercase, fontSize, fontWeight } = props;
  return (
    <Component
      {...props}
      className={cn(
        className,
        align ? styles[`align-${align}`] : "",
        serif ? styles.fontSerif : styles.fontSans,
        uppercase ? styles.uppercase : "",
        fontSize ? styles[fontSize] : "",
        fontWeight ? styles[fontWeight] : ""
      )}
    />
  );
};

const withBodyProps = (Component: FC<BodyProps>): FC<BodyProps> => (props) => {
  const { className, align, fontType, fontSize, limitChars, children, fontWeight, sans, serif } = props;
  const [isPhone, setIsPhone] = useState(false);

  // Helper function to truncate text
  const truncateText = (text: string, limit: number): string => {
    if (text.length <= limit) return text;
    return text.slice(0, limit) + "...";
  };

  useEffect(() => {
    // Check if window is defined (client-side only)
    const handleResize = () => {
      setIsPhone(window.innerWidth <= 768);
    };

    if (typeof window !== "undefined") {
      handleResize(); // Set initial value
      window.addEventListener("resize", handleResize);

      // Cleanup event listener on unmount
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const truncatedChildren = isPhone && limitChars ? truncateText(children as string, limitChars) : children;

  return (
    <Component
      {...props}
      className={cn(
        className,
        align ? styles[`align-${align}`] : "",
        fontType ? styles[fontType] : "",
        sans ? styles.fontSans : "",
        serif ? styles.fontSerif : "",
        fontSize ? styles[fontSize] : "",
        fontWeight ? styles[fontWeight] : ""
      )}
    >
      {truncatedChildren}
    </Component>
  );
};

const Heading1: FC<HeadingProps> = withHeadingProps((props) => (
  <h1 {...props} className={cn(styles.Heading1, props.className)}>
    {props.children}
  </h1>
));
const Heading2: FC<HeadingProps> = withHeadingProps((props) => (
  <h2 {...props} className={cn(styles.Heading2, props.className)}>
    {props.children}
  </h2>
));
const Heading3: FC<HeadingProps> = withHeadingProps((props) => (
  <h3 {...props} className={cn(styles.Heading3, props.className)}>
    {props.children}
  </h3>
));
const Heading4: FC<HeadingProps> = withHeadingProps((props) => (
  <h4 {...props} className={cn(styles.Heading4, props.className)}>
    {props.children}
  </h4>
));
const Heading5: FC<HeadingProps> = withHeadingProps((props) => (
  <h5 {...props} className={cn(styles.Heading5, props.className)}>
    {props.children}
  </h5>
));
const Heading6: FC<HeadingProps> = withHeadingProps((props) => (
  <h6 {...props} className={cn(styles.Heading6, props.className)}>
    {props.children}
  </h6>
));
const Subtitle1: FC<HeadingProps> = withHeadingProps((props) => (
  <h5 {...props} className={cn(styles.Subtitle1, props.className)}>
    {props.children}
  </h5>
));
const Subtitle2: FC<HeadingProps> = withHeadingProps((props) => (
  <h6 {...props} className={cn(styles.Subtitle2, props.className)}>
    {props.children}
  </h6>
));

const Body1: FC<BodyProps> = withBodyProps((props) => (
  <p {...props} className={cn(styles.Body1, props.className)}>
    {props.children}
  </p>
));
const Body2: FC<BodyProps> = withBodyProps((props) => (
  <p {...props} className={cn(styles.Body2, props.className)}>
    {props.children}
  </p>
));
const Caption: FC<BodyProps> = withBodyProps((props) => (
  <p {...props} className={cn(styles.Caption, props.className)}>
    {props.children}
  </p>
));
const Overline: FC<BodyProps> = withBodyProps((props) => (
  <p {...props} className={cn(styles.Overline, props.className)}>
    {props.children}
  </p>
));

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  Caption,
  Overline,
};
