import { cn } from "@lib";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./HorizontalScroll.module.scss";

export const HorizontalScroll = ({ children, className }) => {
  return <div className={cn(className, styles.container)}>{children}</div>;
};

HorizontalScroll.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};
