import { cn } from "@lib";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./FloatingBox.module.scss";

export const FloatingBox = ({ children, align, className, position }) => {
  let alignClassName;
  if (align === "right") {
    alignClassName = styles.right;
  } else if (align === "left") {
    alignClassName = styles.left;
  } else {
    alignClassName = styles.center;
  }

  let positionClassName;
  if (position === "top") {
    positionClassName = styles.top;
  } else if (position === "bottom") {
    positionClassName = styles.bottom;
  } else {
    positionClassName = "";
  }

  return (
    <div className={cn(className, styles.box, alignClassName, positionClassName)}>{children}</div>
  );
};

FloatingBox.defaultProps = {
  align: "right",
  position: null,
};

FloatingBox.propTypes = {
  children: PropTypes.any.isRequired,
  align: PropTypes.string.isRequired,
  position: PropTypes.string,
};
