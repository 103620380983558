import { Icon } from "@components";
import { Link } from "gatsby";
import * as React from "react";

import { container } from "./ArrowButton.module.scss";

interface IProps {
  href: string;
  children: React.ReactNode;
  color: string;
}

export const ArrowButton: React.FC<IProps> = ({ children, href, color }) => {
  return (
    <Link to={href} style={{ color }} className={container}>
      <div>{children}</div>
      <Icon symbol="arrowForward" viewBox="0 0 36 24" stroke="inherit" />
    </Link>
  );
};
