import React from "react";
import { useLocation } from "@reach/router"; // Import useLocation hook
import { NavLink } from "./MultiplyTexHover/MultiplyTexHover";

interface NavItemProps {
  name: string;
  href: string;
  onClick?: () => void;
}

export const NavItem: React.FC<NavItemProps> = ({ name, href, onClick }) => {




  return (
    <li>
      <NavLink name={name} href={href} onClick={onClick} />
    </li>
  );
};
