import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactMapGL, { NavigationControl } from "react-map-gl";

export const Mapbox = ({
  width,
  height,
  scroll,
  zoom,
  children,
  center,
  setPopupInfo,
}) => {
  console.log(process.env.MAPBOX_API_KEY);

  const mapboxApiAccessToken =
  "pk.eyJ1Ijoib3Nsb2J1a3RhIiwiYSI6ImNrOWUwaWxoMzA5bW8zcm1sd2prc3o1eGwifQ.vmADo8p6hG4B1eTw0R6aTA";

  const [viewport, setViewport] = useState({
    width: "400",
    height: "400",
    latitude: (center && center.lat) || 59.905,
    longitude: (center && center.lng) || 10.759,
    zoom: 13.28,
    mapboxApiAccessToken,
  });

  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass =
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

  return (
    <ReactMapGL
      {...viewport}
      width={width}
      height={height}
      onViewportChange={setViewport}
      mapStyle="mapbox://styles/oslobukta/cl6nds1kf002214qr5i1p3879"
      dragPan={scroll}
      touchZoom={zoom}
      doubleClickZoom={zoom}
      scrollZoom={zoom}
      mapboxApiAccessToken={mapboxApiAccessToken}
      mapboxAccessToken={mapboxApiAccessToken}
      onClick={() => setPopupInfo(false)}
    >
      {!scroll && (
        <div style={{ position: "absolute", right: 0 }}>
          <NavigationControl />
        </div>
      )}
      {children}
    </ReactMapGL>
  );
};

Mapbox.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  scroll: PropTypes.bool,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  children: PropTypes.any,
  setPopupInfo: PropTypes.func,
};
